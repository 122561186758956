import React from "react"
import { withLayout } from "../components/layout"
import SEO from "../components/seo"
import styled, { withTheme } from "styled-components"
import HeadlineOrnament from "../components/headlineOrnament"

import logoSrc from "../images/logo-route.png"
import federHor from "../images/feder-auge-horizontal.png"
import { device } from "../util/media"
import { Heading } from "../components/heading"
import DateTime from "../components/dateTime"

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Logo = styled.img.attrs({
  src: logoSrc,
})`
  margin: 15px 0;
  width: 200px;
  @media ${device.tablet} {
    width: 300px;
  }
  @media ${device.laptop} {
    width: 400px;
  }
`

const LightContainer = styled.div`
  ${"" /* background: rgba(255, 255, 255, 0.1);
  padding: 40px;
  border-radius: 10px; */}
`

const IndexPage = withLayout(
  withTheme(({ theme }) => {
    return (
      <>
        <SEO title="Einladung zur Hochzeit" />
        <HeadlineOrnament color={theme.primary}>
          <Heading>Wir sagen Ja</Heading>
        </HeadlineOrnament>
        <Center>
          <Logo />
          <Heading>Feiert mit uns</Heading>
          <LightContainer>
            <DateTime />
          </LightContainer>

          <img src={federHor} />
        </Center>
      </>
    )
  })
)

export default IndexPage
